import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Collapse, List } from '@mui/material';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { IFeatureFlagEnabled } from 'shared/interfaces/iclient-feature';
import { RouteCategory, RouteControlPage } from 'shared/service/router/route-control';
import { ListItemLink } from './ListItemLink';
import { StringHelper } from 'shared/utils/string-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import styled from '@emotion/styled';

/**
 * Styling
 */
const MenuLinkDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  border: solid 1px var(--hr);

  // &:hover {
  //   background-color: var(--color-secondary-light);
  // }
`;

/**
 * SidebarList interface definition
 * @interface
 */
interface ISidebarListProp {
  route: IRoutePath;
  key?: string;
  depth: number;
  routeCategory: RouteCategory;
  isFeatureFlagEnabled: IFeatureFlagEnabled;
}

/**
 * Sidebar list generator
 * @param props routes and key
 */
export const SidebarList = (props: ISidebarListProp) => {
  const { route, depth, routeCategory, isFeatureFlagEnabled } = props;
  const [isEnabled, setIsEnabled] = useState(false);

  const [open, setOpen] = useState(route.isExpanded ?? false);
  const isExpandable = (route.children?.length || 0) > 0;
  const expandMore = isExpandable && !open;
  const expandLess = isExpandable && open;

  const { user, actor } = useAppSelector((state) => state.profile);
  const isDisplayed = route.isDisplayed && isEnabled;
  const isLayoutPage = !!route.isLayoutPage;


  const routeControlPage = new RouteControlPage();

  useEffect(() => {
    //  Display Main Page using actor and feature flag
    if (routeCategory === RouteCategory.MAIN_PAGE) {
      const isEnabledMainPageRoute = routeControlPage.routeControlMainPage({ route, actor, isFeatureFlagEnabled });
      setIsEnabled(isEnabledMainPageRoute);
    }

    //  Display Admin Page using actor and feature flag
    if (routeCategory === RouteCategory.ADMIN_PAGE) {
      const isEnabledAdminPageRoute = routeControlPage.routeControlAdminPage({ route, user });
      setIsEnabled(isEnabledAdminPageRoute);
    }

    if (
      typeof actor.domainId === 'undefined' ||
      typeof actor.corpId === 'undefined' ||
      typeof actor.corporationId === 'undefined'
    ) {
      vlogger.warning(
        `${actor.name} (clientId: ${actor.clientId}) has an undefined domainId (${actor.domainId}), corpId (${actor.corpId}), or corporationId (${actor.corporationId})`
      );
    }

    //  override routes if actor is not active
    if (!actor.isActive) {
      setIsEnabled(false);
    }

    //  if beta route then user must have isBeta enabled
    if (route.isBeta && user.isBeta !== true) {
      setIsEnabled(false);
    }
  }, [actor]);

  const handleClick = () => {
    setOpen(!open);
  };

  const elementKey = StringHelper.SpaceRemover(`list-${route.displayName}-${depth}`, '-').toLowerCase();

  const MenuRoot = isDisplayed ? (
    <MenuLinkDiv>
      <ListItemLink
        className='item'
        to={route.path}
        primary={route.displayName}
        depth={depth + 1}
        id={elementKey}
        expandMore={expandMore}
        expandLess={expandLess}
        isLink={!!route.element}
        onClick={handleClick}
        key={elementKey}
      />
    </MenuLinkDiv>
  ) : null;

  const MenuChildren =
    isExpandable && (isDisplayed || isLayoutPage) ? (
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding className='item'>
          {route.children!.map((r) => {
            const elementKey = StringHelper.SpaceRemover(
              `${route.displayName}-${r.displayName}-${depth}`,
              '-'
            ).toLowerCase();
            return <SidebarList route={r} depth={depth + 1} routeCategory={routeCategory}
              isFeatureFlagEnabled={isFeatureFlagEnabled} key={elementKey}
                   />;
          })}
        </List>
      </Collapse>
    ) : null;

  return (
    <>
      {MenuRoot}
      {MenuChildren}
    </>
  );
};


