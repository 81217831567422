import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { featureFlagValues, IClientFeature, IFeatureFlag } from 'shared/interfaces/iclient-feature';
import { ClientSettingsService, IClientFeatureRequest } from 'shared/service/client-feature/client-feature-service';
import { vlogger } from 'shared/service/logger/vlogger';

/**
 * Interface for Client Features
 */
export interface IClientFeatureFlags {
  featureFlags: IFeatureFlag[];
  clientsFeatures: IClientFeature[];
}

/**
 * Default feature flags and client features
 */
export const defaultFeatureFlag: IFeatureFlag = {
  featureId: -1, featureName: featureFlagValues['undetermined'], featureDescription: ''
};
export const defaultClientFeature: IClientFeature = {
  id: -1, clientId: 999, featureFlagId: -1, isEnabled: false
};

/**
 * InitialState
 */
const initialState: IClientFeatureFlags = {
  featureFlags: [defaultFeatureFlag],
  clientsFeatures: [defaultClientFeature],
};

/**
 * Get feature flag array via Async Thunk
 */
export const fetchFeatureFlags = createAsyncThunk(
  'feature/fetchFeatureFlags',
  async (args: { pca: IPublicClientApplication }, thunkApi) => {
    const clientSettingsService = new ClientSettingsService();
    return await clientSettingsService.getFeatureFlags(args.pca);
  }
);

/**
 * Get client feature array via Async Thunk
 */
export const fetchClientFeatures = createAsyncThunk(
  'feature/fetchClientFeatures',
  async (args: { pca: IPublicClientApplication; clientId?: number | undefined }, thunkApi) => {
    const clientSettingsService = new ClientSettingsService();
    const clientsFeatures = !args.clientId
      ? await clientSettingsService.getClientsFeatures(args.pca)
      : await clientSettingsService.getClientFeatures(args.pca, { clientId: args.clientId } as IClientFeatureRequest);
    return clientsFeatures;
  }
);

/**
 * Update client feature array via Async Thunk
 */
export const addUpdateClientFeatures = createAsyncThunk(
  'feature/addUpdateClientFeatures',
  async (args: { pca: IPublicClientApplication; cf: IClientFeature }, thunkApi) => {
    const request: IClientFeatureRequest = {
      clientId: args.cf.clientId,
      featureId: args.cf.featureFlagId,
      isEnabled: args.cf.isEnabled,
    };

    const response = await new ClientSettingsService().updateClientFeature(args.pca, request);
    if (response)
      vlogger.verbose(`update clientFeature -- (clientId: ${request.clientId}, feature: ${request.featureId}, value: ${request.isEnabled})`);
    else
      vlogger.error(`failed to update clientFeature -- (clientId: ${request.clientId}, feature: ${request.featureId})`);

    return response;
  }
);

export const featureSlice = createSlice({
  name: 'feature',
  initialState: initialState,
  reducers: {
    addUpdateClientFeature: (state, action) => {
      const newFeature = action.payload as IClientFeature;
      const clientsFeatures: IClientFeature[] = state.clientsFeatures;

      const index =
        clientsFeatures.findIndex(cf => cf.clientId === newFeature.clientId && cf.featureFlagId === newFeature.featureFlagId);
      if (index < 0) {
        clientsFeatures.push(newFeature);
      } else {
        clientsFeatures[index].isEnabled = newFeature.isEnabled;
        state.clientsFeatures = clientsFeatures;
      }
    },
  },
  extraReducers: (builder) => {
    /**
     * Fetch Feature Flags
     */
    builder.addCase(fetchFeatureFlags.pending, (state, action) => {
      // vlogger.debug('fetchFeatureFlags pending');
    });

    builder.addCase(fetchFeatureFlags.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        state.featureFlags = result;
      } else {
        vlogger.warning('fetchFeatureFlags returned null');
        state.featureFlags = initialState.featureFlags;
      }
    });

    builder.addCase(fetchFeatureFlags.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchFeatureFlags failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.featureFlags = initialState.featureFlags;
    });


    /**
     * Fetch All Clients Features
     */
    builder.addCase(fetchClientFeatures.pending, (state, action) => {
      // vlogger.debug('fetchClientFeatures pending');
    });

    builder.addCase(fetchClientFeatures.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        state.clientsFeatures = result;
      } else {
        vlogger.warning('fetchClientFeatures returned null');
        state.clientsFeatures = initialState.clientsFeatures;
      }
    });

    builder.addCase(fetchClientFeatures.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchClientFeatures failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.clientsFeatures = initialState.clientsFeatures;
    });

    /**
     * Add Or Update All Clients Features
     * @remarks doesn't update local state
     */
    builder.addCase(addUpdateClientFeatures.pending, (state, action) => {
      // vlogger.debug('addUpdateClientFeatures pending');
    });

    builder.addCase(addUpdateClientFeatures.fulfilled, (state, action) => {
      const result = action.payload;
      if (!result) {
        vlogger.warning('addUpdateUser returned null');
        return;
      }

      const clientsFeatures: IClientFeature[] = state.clientsFeatures;
      const index =
        clientsFeatures.findIndex(cf => cf.clientId === result.clientId && cf.featureFlagId === result.featureFlagId);
      if (index < 0) {
        state.clientsFeatures = [...state.clientsFeatures, result];
      } else {
        const cf = clientsFeatures.filter((cf, i) => i !== index);
        state.clientsFeatures = [...cf, result];
      }
    });

    builder.addCase(addUpdateClientFeatures.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`addUpdateClientFeatures failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
    });
  }
});


export const { addUpdateClientFeature } = featureSlice.actions;

export const featureReducer = featureSlice.reducer;


