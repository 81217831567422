import React, { MouseEvent } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IVipUser } from 'redux/profileSlice';
import { ApplicationSection, Permission, hasAccess } from 'App/User/user-role';
import { featureFlagValues } from 'shared/interfaces/iclient-feature';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { SortedDescendingIcon, SortedAscendingIcon } from 'shared/components/Datagrid/SortedIcon';
import { Checkbox, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';


/**
 * Styling
 */
const DataGridDiv = styled.div`
  & .checkbox-cell {
    color: var(--color-primary);
    width: 2rem;
    height: 2rem;
  
  & .center-cell {
      display: flex;
      align-items: center;
    }
  }
`;

/**
 * Client Feature data grid row
 */
export interface IClientFeatureDataRow {
  clientId: number;
  name: string;
  products: string[];
  isMultiMonth?: boolean;

  //  Product Feature flags
  isInsights: boolean;
  isArsFp: boolean;
}

/**
 * Client feature click event data object
 */
export interface IClientFeatureClickModel {
  clientId: number;
  flag: string;
  value: boolean;
}

/**
 * Props passed 
 */
interface IClientDataTableProps {
  clientFeatures: IClientFeatureDataRow[];
  onFeatureClick: (event: MouseEvent<HTMLButtonElement>, eventdata: IClientFeatureClickModel) => void;
}

/**
 * Keep the tooltip text from wrapping
 */
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});


/**
 * Client data grid component
 * @param {IClientDataTableProps} props 
 * @returns 
 */
export const ClientDataTable = (props: IClientDataTableProps) => {
  const { clientFeatures, onFeatureClick } = props;
  const user: IVipUser = useAppSelector((state) => state.profile.user);
  const rowsPerPage: number[] = [5, 10, 20];
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

  const canEditFeatureFlag = hasAccess(user, ApplicationSection.CLIENT_ADMIN, Permission.EDIT);

  const toolTip = 'The Multiple Month Revenue Model is not set.<br/>Contact Bradford to update in SRM';

  //  DataGrid Column definition
  const columns: GridColDef[] = [
    { field: 'colClientId', headerName: 'Client ID', flex: 1, maxWidth: 180, headerAlign: 'left' },
    { field: 'colName', headerName: 'Name', flex: 1, minWidth: 250, headerAlign: 'left' },
    { field: 'colProds', headerName: 'Products', flex: 1, minWidth: 190, headerAlign: 'left' },
    {
      field: 'colArsFp', headerName: 'ARS FP', flex: 1, maxWidth: 180, headerAlign: 'center', type: 'boolean',
      renderCell: (params: GridCellParams) => (
        <NoMaxWidthTooltip className='tooltip' placement='top'
          title={!params.row.colIsMM ? <span dangerouslySetInnerHTML={{ __html: toolTip }} /> : ''}
        >
          <span>    {/*  this is required for the tooltip to work on disabled button  */}
            <Checkbox className="checkbox-cell center-cell" size="medium" checked={params.row.colArsFp}
              disabled={!canEditFeatureFlag || !params.row.colIsMM}
              onClick={(e) => {
                onFeatureClick(e, {
                  clientId: params.row.colClientId,
                  flag: featureFlagValues['ars_fp'],
                  value: !params.value
                });
              }}
            />
          </span>
        </NoMaxWidthTooltip>
      )
    },
    {
      field: 'colInsights', headerName: 'Insights', flex: 1, maxWidth: 180, headerAlign: 'center', type: 'boolean',
      renderCell: (params: GridCellParams) => (
        <Checkbox className="checkbox-cell center-cell" size="medium" checked={params.row.colInsights}
          disabled={!canEditFeatureFlag}
          onClick={(e) => {
            onFeatureClick(e, {
              clientId: params.row.colClientId,
              flag: featureFlagValues['insights'],
              value: !params.value
            });
          }}
        />
      )
    },
  ];

  let index = 1;
  const rows = clientFeatures.map(c => {
    return {
      id: index++,
      colClientId: c.clientId,
      colName: c.name,
      colProds: c.products.join(', '),
      colArsFp: c.isArsFp,
      colIsMM: c.isMultiMonth,          //  isMultiMonthRevModel enabled for this client
      colInsights: c.isInsights,
    };
  });

  return (
    <DataGridDiv id='client-feature-table'>
      <DataGrid rows={rows} columns={columns}
        pagination
        pageSizeOptions={rowsPerPage}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
        }}
      />
    </DataGridDiv>
  );
};
