import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserProfileService } from 'shared/service/profile/user-profile';
import { SectionPermission, UserRole, FIUserPermissions, canImpersonateHelper } from 'App/User/user-role';
import { vlogger } from 'shared/service/logger/vlogger';
import appConstants from 'App/app-constants';


/**
 * Interface for IVipUser
 * @interface
 */
export interface IVipUser {
  userId?: number;
  displayName?: string;
  firstName: string;
  lastName: string;
  email: string;
  clientId: number;
  isDisabled?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isBeta?: boolean | undefined;
  userRole: UserRole;
  permission: SectionPermission;
}

/**
 * Interface for IVipCompany
 * @interface
 */
export interface IVipCompany {
  name: string;
  clientId: number;
  domainId: number;
  corpId?: number;
  corporationId?: number;
  isActive?: boolean;
  isHosted?: boolean;
  isRepresentment?: boolean;
  isILS?: boolean;
  isARS?: boolean;
  isMRPC?: boolean;
  isCP?: boolean;
  isICS?: boolean;
  isOIS?: boolean;
  isODM?: boolean;
  isMultiMonthRevenueModel?: boolean;
}


/**
 * IProfile interface
 * @interface
 */
export interface IProfile {
  user: IVipUser;
  company: IVipCompany;
  actor: IVipCompany;
}

/**
 * Default user and company
 */
export const defaultUserPermissions: SectionPermission = FIUserPermissions;

export const defaultUser: IVipUser = {
  userId: appConstants.default_userId,
  displayName: '', firstName: 'Demo', lastName: 'User',
  email: 'email@email.com',
  clientId: appConstants.default_clientId,
  isDisabled: false,
  isDeleted: false,
  isBeta: false,
  userRole: UserRole.FI_USER,
  permission: defaultUserPermissions
};

export const defaultCompany: IVipCompany = {
  name: appConstants.default_companyName, clientId: appConstants.default_clientId, domainId: 30, corpId: 2905, corporationId: 2905,
  isActive: true, isHosted: false, isRepresentment: false,
  isILS: false, isARS: true, isMRPC: false, isCP: false, isICS: false, isOIS: false, isODM: false, isMultiMonthRevenueModel: false,
};

const initialState: IProfile = {
  user: defaultUser,
  company: defaultCompany,
  actor: defaultCompany,
};

/**
 * Redux Toolkit slice helper (=> reducer definitions)
 */
/**
 * Get specific registered user
 */
export const fetchUser = createAsyncThunk(
  'profile/fetchUser',
  async (args: { pca: IPublicClientApplication; username: string }, thunkApi) => {
    const userProfileService = new UserProfileService(args.pca, vlogger);
    const user = await userProfileService.getUser(args.username);
    return user;
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    userAddUpdate: (state, action) => {
      const user: IVipUser = action.payload;
      state.user = user;
    },

    companyAdd: (state, action) => {
      const company: IVipCompany = action.payload;
      state.company = company;
      state.actor = company;
    },

    userImpersonate: (state, action) => {
      const user = action.payload.user;
      if (canImpersonateHelper(user)) {
        state.actor = action.payload.actor;
      }
    }
  },
  extraReducers: builder => {
    /**
     * Fetch User
     */
    builder.addCase(fetchUser.pending, (state, action) => {
      // vlogger.debug('fetchUser pending');
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        const vipUser: IVipUser = result;
        if (!vipUser.displayName) {
          vipUser.displayName = `${vipUser.firstName} ${vipUser.lastName}`;
        }
        state.user = vipUser;
      } else {
        vlogger.warning('fetchUser returned null');
      }
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchUser failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.user = initialState.user;
    });
  }
});

export const { userAddUpdate, companyAdd, userImpersonate } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;

