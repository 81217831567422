import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BusinessResult } from 'shared/model/business-result';
import { VelocityError } from 'shared/model/velocity-error';
import { ILogger } from 'shared/service/logger/vlogger';

/**
 * Http Service Headers
 */
export interface IHeaders {
  [key: string]: string;
}

/**
 * Http Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IHttpService {
  get<T>(relativeUrl: string, params: object, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null>;
  delete<T>(relativeUrl: string, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null>;
  post<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null>;
  put<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null>;
  patch<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null>;
}


/** 
 * Class representing a Http Service object
 * @class
 * @implements IHttpService
 */
export class HttpService implements IHttpService {
  private _axiosConfig: AxiosRequestConfig;
  private _logger: ILogger;

  /**
   * Constructor
   * @constructor
   * @param {Logger} logger logging instance
   * @param {string} baseUrl website root url
   * @param {IHeaders} headers http context headers applied to ALL http transactions
   */
  public constructor(logger: ILogger, baseUrl: string, headers: IHeaders = {}) {
    this._axiosConfig = { baseURL: baseUrl, headers: headers };
    this._logger = logger;
  }


  /**
   * Http Post
   * @param {string} relativeUrl Relative URL to the endpoint
   * @param {S} body Post body
   * @param {IHeaders} headers http context headers
   * @param {string} operationId Operation or Correlation ID for tracking request
   * @returns {BusinessResult<T>} post results or error
   */
  async post<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null> {
    const axiosConfig: AxiosRequestConfig = { ...this._axiosConfig, headers: this._addOperationId(headers, operationId) };

    const result = await axios.post<T>(relativeUrl, body, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    result.operationId = (!result.operationId) ? operationId : result.operationId;
    return result;
  }

  /**
   * Http Put
   * @param {string} relativeUrl Relative URL to the endpoint
   * @param {S} body Put body
   * @param {IHeaders} headers http context headers
   * @param {string} operationId Operation or Correlation ID for tracking request
   * @returns {BusinessResult<T>} put results or error
   */
  async put<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null> {
    const axiosConfig: AxiosRequestConfig = { ...this._axiosConfig, headers: this._addOperationId(headers, operationId) };

    const result = await axios.put<T>(relativeUrl, body, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    result.operationId = (!result.operationId) ? operationId : result.operationId;
    return result;
  }

  /**
   * Http Patch
   * @param {string} relativeUrl Relative URL to the endpoint
   * @param {S} body Patch body
   * @param {IHeaders} headers http context headers
   * @param {string} operationId Operation or Correlation ID for tracking request
   * @returns {BusinessResult<T>}  patch results or error
   */
  async patch<S, T>(relativeUrl: string, body: S, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null> {
    const axiosConfig: AxiosRequestConfig = { ...this._axiosConfig, headers: this._addOperationId(headers, operationId) };

    const result = await axios.patch<T>(relativeUrl, body, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    result.operationId = (!result.operationId) ? operationId : result.operationId;
    return result;
  }

  /**
   * Http Get
   * @param {string} relativeUrl Relative URL to the endpoint
   * @param {object} params query string parameters
   * @param {IHeaders} headers http context headers
   * @param {string} operationId Operation or Correlation ID for tracking request
   * @returns {BusinessResult<T>}  get results or error
   */
  async get<T>(relativeUrl: string, params: object, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null> {
    const axiosConfig: AxiosRequestConfig = { ...this._axiosConfig, params: params, headers: this._addOperationId(headers, operationId) };

    const result = await axios.get<T>(relativeUrl, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    result.operationId = (!result.operationId) ? operationId : result.operationId;
    return result;
  }

  /**
   * Http Delete
   * @param {string} relativeUrl Relative URL to the endpoint
   * @param {IHeaders} headers http context headers
   * @param {string} operationId Operation or Correlation ID for tracking request
   * @returns {BusinessResult<T>}  delete results or error
   */
  async delete<T>(relativeUrl: string, headers: IHeaders, operationId: string): Promise<BusinessResult<T> | null> {
    const axiosConfig: AxiosRequestConfig = { ...this._axiosConfig, headers: this._addOperationId(headers, operationId) };

    const result = await axios.delete<T>(relativeUrl, axiosConfig)
      .then((response) => this._handleResponse<T>(response))
      .catch((error) => this._handleError<T>(error));

    result.operationId = (!result.operationId) ? operationId : result.operationId;
    return result;
  }

  /**
   * Add header context operationId
   * @param {IHeaders} headers existing header dictionary
   * @param {string} operationId  unique http transaction identifier
   * @returns {IHeaders} new header dictionary
   */
  private _addOperationId(headers: IHeaders, operationId: string): IHeaders {
    return { ...headers, 'OperationId': operationId, 'X-RequestId': operationId };
  }

  /**
   * Http success response handler
   * @param {AxiosResponse} response http response object
   * @returns {BusinessResult<T>}  contains http response plus error
   */
  private _handleResponse<T>(response: AxiosResponse) {
    this._logger.verbose(`Http Response: url: ${response.config.url} status: ${response.status}, statusText: ${response.statusText}`, false, false);
    const result = new BusinessResult<T>();
    result.result = response.data.result as T;
    result.operationId = response.data.operationId || '';
    result.errors = response.data.errors ?? [];

    return result;
  }

  /**
   * Http error response handler
   * @param {AxiosError} error http error object
   * @returns {BusinessResult<T>} contains http response plus error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleError<T>(error: AxiosError<BusinessResult<T>, any>) {
    this._logger.error(`Http Error: name: ${error?.name}, status: ${error?.code || 500}, url: ${error?.config?.url}, message: ${error?.message}`, false, false);
    this._logger.verbose(`Config Headers: ${JSON.stringify(error?.config?.headers)}`, false, false);
    this._logger.verbose(`Config Data: ${JSON.stringify(error?.config?.data)}`, false, false);
    this._logger.verbose(`Stack: ${error.stack || 'undefined'}`, false, false);

    const errorMessage = `Http Error: name: ${error?.name}, status: ${error?.code || 500}, message: ${error?.message}\n` +
      `Headers: ${JSON.stringify(error?.config?.headers || '')}\n` +
      `Data: ${JSON.stringify(error?.config?.data || '')}`;
    // `Data: ${JSON.stringify(error?.config?.data || '')}\n` +
    // `Stack: ${error?.stack || ''}`;
    const result = new BusinessResult<T>();
    result.result = error.response?.data?.result as T;
    result.errors.push(new VelocityError(errorMessage));
    return result;
  }
}
