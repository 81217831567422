
import appConstants from 'App/app-constants';


/**
 * Static Email Helper class
 * @class
 */
export class EmailHelper {

  static isVelocityEmail(s?: string): boolean {
    return s ? s.substring(s.indexOf('@') + 1).toLowerCase() === appConstants.velocityDomain : false;
  }
}
