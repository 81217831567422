import { IPublicClientApplication, InteractionRequiredAuthError, BrowserAuthError, AuthError, AuthenticationResult } from '@azure/msal-browser';
import { vlogger } from 'shared/service/logger/vlogger';
import { LOGINREQUEST } from 'App/auth-configuration';

export async function getAccessToken(pca: IPublicClientApplication): Promise<string> {
  const account = pca.getAllAccounts()[0];
  if (!account) {
    vlogger.error('Not an authorized user');
    // await pca.logoutRedirect();
  }

  let accessToken = '';
  await pca
    .acquireTokenSilent({
      ...LOGINREQUEST,
      account: account,
      redirectUri: '/redirect.html',
    })
    .then((response: AuthenticationResult) => {
      accessToken = response.accessToken;
    })
    .catch(async (error: Error) => {
      vlogger.informational(`error: ${JSON.stringify(error)}`);
      vlogger.debug(`logged in: ${account ? account.username : 'no account'}`);

      if (error instanceof BrowserAuthError) {
        vlogger.debug(
          'browserAuthError error:\n' +
          `\tcode (${error.errorCode}), message (${error.message})\nlogging out`,
          true
        );
        await pca.logoutRedirect();

      } else if (error instanceof InteractionRequiredAuthError) {
        vlogger.debug(
          'interactionRequiredAuthError error:\n' +
          `\tcode (${error.errorCode}), message (${error.message}: ${error.subError})`,
          true
        );
        await pca.acquireTokenRedirect({ ...LOGINREQUEST, account });

      } else if (error instanceof AuthError) {
        vlogger.debug(
          'unspecified authorization token acquisition error:\n' +
          `\tcode (${error.errorCode}), message (${error.message}: ${error.subError})`,
          true
        );
      } else {
        vlogger.debug(
          `error type: ${typeof (error)}\n` +
          `\tunspecified token acquisition error:\n cause (${error.cause})`,
          true
        );
      }
      // await pca.logoutRedirect();
    });

  vlogger.verbose(`token: ${accessToken}`);
  return Promise.resolve(accessToken);
}
