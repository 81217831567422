import { IDictionary } from 'shared/interfaces/idictionary';

/**
 * Intermediate local variables
 */
// const one_minutes = 1 * 60;
// const fifteen_minutes = 15 * 60;   
const four_hours = 4 * 60 * 60;


/**
 * Application constants
 */
//  products array:  mapping product abbreviation to name
export const products: IDictionary<string, string> = {
  ars: 'Account Revenue Solution™',
  cp: 'CashPlease®',
  mrpc: 'My Rewards® Premium Cards',
  ils: 'Intelligent Limit System®',
  ics: 'Invitation Checking® System',
  ois: 'Reg. E Outreach Solution™',
};

//  Report section product promotion
export interface IProductPromotion {
  name: string;
  promoLine: string;
  learnMoreLink: string;
  learnMoreLinkText: string;
}

export const productsPromotion: IDictionary<string, IProductPromotion> = {
  ars: {
    name: products['ars'],
    promoLine: 'Uses analytics and segmentation to drive consumer engagement and revenue',
    learnMoreLink: 'https://myvelocity.com/solutions/account-acquisition-and-profitability/account-revenue-solution/',
    learnMoreLinkText: 'LEARN MORE',
  },
  mrpc: {
    name: products['mrpc'],
    promoLine: 'Generate revenue by tying premium rewards and benefits to the debit card',
    learnMoreLink: 'https://myvelocity.com/solutions/account-acquisition-and-profitability/my-rewards-premium-cards/',
    learnMoreLinkText: 'LEARN MORE',
  },
  cp: {
    name: products['cp'],
    promoLine: 'Small-dollar, short-term consumer loan platform providing affordable access to cash',
    learnMoreLink: 'https://myvelocity.com/solutions/digital-lending/cashplease/',
    learnMoreLinkText: 'LEARN MORE',
  },
  ils: {
    name: products['ils'],
    promoLine: 'Automated solution that revolutionizes overdraft management using customized limits',
    learnMoreLink: 'https://myvelocity.com/solutions/revenue-and-compliance-management/intelligent-limit-system/',
    learnMoreLinkText: 'LEARN MORE',
  },
  ics: {
    name: products['ics'],
    promoLine: 'Increase deposits through profitable new accounts with a data-driven and digital approach',
    learnMoreLink: 'https://myvelocity.com/solutions/account-acquisition-and-profitability/invitation-checking-system/',
    learnMoreLinkText: 'LEARN MORE',
  },
  ois: {
    name: products['ois'],
    promoLine: 'Address lost income and account holder dissatisfaction resulting from lack of Reg. E decisions',
    learnMoreLink: 'https://myvelocity.com/solutions/revenue-and-compliance-management/reg-e-outreach-solution/',
    learnMoreLinkText: 'LEARN MORE',
  },
};

//  storage status (access tiers plus deleted)
export enum StorageStatus {
  'hot' = 0,
  'cool' = 1,
  'archive' = 2,
  'deleted' = 3,
  'undetermined' = 4,
}


//  default
export default {
  default_clientId: 999,  //  clientId = 999 = Union Financial Bank
  default_companyName: 'Union Financial Bank',
  demo_clientId: 999,     //  demo clientId = 999 = Union Financial Bank
  default_userId: -1,     //  invalid userId

  //  assets
  imageDirectory: 'https://velocitysolutionswebassets.azureedge.net/webassets/images',
  iconDirectory: '/assets/icons',

  velocityDomain: 'myvelocity.com',

  //  dashboard parameters
  //     session_length and dashboard_timeout can be between 0 sec and 30 days
  session_length: four_hours,
  session_timeout: four_hours * 1000, // '* 1000' converts from sec to milisec
  // session_timeout: 10 * 1000,           //  10 sec for testing
  dashboard_timeout: four_hours * 1000, // '* 1000' converts from sec to milisec
  dashboard_min_height: 300,

  //  reports
  reportFilenameDelimiter: '-',
  reportsNameFormat: {
    clientId: 0,
    date: 1,
    product: 2,
  },
  // reportsAcceptFileFormats: ['.doc', '.docx', '.pdf', '.ppt', '.pptx', '.xls', '.xlsx', '.csv'],
  reportsAcceptedFilenaming: 'Client ID-YYYY.MM-Product Abrv.-File Name',
  reportsAcceptFileFormats: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/pdf',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.pbix'
  ],

  //  Max Number of error messages to display when upload file check is verified
  reportsUploadMaxNumberOfErrors: 2,

  reportsErrorMessages: {
    clientId: 'Client ID is not found, please rename.',
    date: 'Incorrect date format, please rename.',
    dateYearMonth: 'Year must be 4 digits and Month must be 2 digits, please rename',
    product: 'No Product name found, please rename.',
    fileType: 'This file type is not supported, please use Word, PBIX, PDF, PowerPoint, Excel, or CSV.',
    fileName: 'Incorrect file naming convention, please rename.',
    fileTooLarge: 'File size is over 25MB, please remove.',
    fileSize: 'File contains no data, please remove.',
    virusFound: 'This is a malicious file, please remove',
  },
};

