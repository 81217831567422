import { IPublicClientApplication } from '@azure/msal-browser';
import { IClientFeature, IFeatureFlag } from 'shared/interfaces/iclient-feature';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { GuidHelper } from 'shared/utils/guid-helper';
import { vlogger } from 'shared/service/logger/vlogger';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';
import { JwtHelper } from 'shared/utils/jwt-helper';


/**
 * Client Feature Flag request / response interface
 */
export interface IClientFeatureRequest {
  clientId?: number;
  featureId?: number;
  isEnabled?: boolean;
  orderBy?: boolean;   //  false returns ascending order; true returns descending order
}

export interface IClientFeatureResponse {
  numberEntries: number;
}


/**
 * Client Settings Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IClientSettingsService {
  getFeatureFlags(pca: IPublicClientApplication, orderBy?: boolean): Promise<IFeatureFlag[] | null>;
  getClientsFeatures(pca: IPublicClientApplication, orderBy?: boolean): Promise<IClientFeature[] | null>;
  getClientFeatures(pca: IPublicClientApplication, request: IClientFeatureRequest): Promise<IClientFeature[] | null>;
  updateClientFeature(pca: IPublicClientApplication, request: IClientFeatureRequest): Promise<IClientFeature | null>;
}

/** 
 * Class representing the Client Settigns Service http interface object
 * @class
 * @implements IClientSettingsService
 */
export class ClientSettingsService implements IClientSettingsService {

  async getFeatureFlags(pca: IPublicClientApplication, orderBy = false): Promise<IFeatureFlag[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const request: IClientFeatureRequest = { orderBy: orderBy };
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.get<IFeatureFlag[]>('api/v1/featureflag/getAllFeatureFlags', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }

  async getClientsFeatures(pca: IPublicClientApplication, orderBy = false): Promise<IClientFeature[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const request: IClientFeatureRequest = { orderBy: orderBy };
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.get<IClientFeature[]>('api/v1/client/feature/getAllClientFeatures', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }

  async getClientFeatures(pca: IPublicClientApplication, request: IClientFeatureRequest): Promise<IClientFeature[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.post<IClientFeatureRequest, IClientFeature[]>('api/v1/client/feature/getAllFeaturesByClient', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }

  async updateClientFeature(pca: IPublicClientApplication, request: IClientFeatureRequest): Promise<IClientFeature | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.post<IClientFeatureRequest, IClientFeatureResponse>('api/v1/client/feature/addFeatureByClient', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return null;
    }

    if (result.result.numberEntries !== 1) {
      vlogger.warning(`updateClientFeature return error value of ${result.result.numberEntries}`);
    }

    return { clientId: request.clientId, featureFlagId: request.featureId, isEnabled: request.isEnabled } as IClientFeature;
  }
}
