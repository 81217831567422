import { IPublicClientApplication } from '@azure/msal-browser';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { vlogger } from 'shared/service/logger/vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';
import { JwtHelper } from 'shared/utils/jwt-helper';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';


/**
 * Interfaces
 */
export interface ISendRegistrationRequest {
  email: string;
  includeComponents: boolean;
}

export interface ISendRegistrationResponse {
  sent: boolean;
  subject?: string;
  body?: string;
  signature?: string;
}

interface IRegistrationService {
  sendRegistrationEmail(pca: IPublicClientApplication, request: ISendRegistrationRequest): Promise<ISendRegistrationResponse | null>;
}


/** 
 * Class representing the Registration Service http interface object
 * @class
 * @implements IRegistrationService
 */
export class RegistrationService implements IRegistrationService {

  async sendRegistrationEmail(pca: IPublicClientApplication, request: ISendRegistrationRequest): Promise<ISendRegistrationResponse | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    const result =
      await httpService.post<ISendRegistrationRequest, ISendRegistrationResponse>('api/v1/user/email/sendaddaccountemail', request, headers, uuid);

    if (result === null || result.errors.length !== 0 || result.result.sent !== true) {
      VelocityErrorHelper.Logify(result?.errors ?? [], result?.operationId, false, false);
      return null;
    } else {
      vlogger.verbose(`${request.email} registration successful`);
      return result.result;
    }
  }
}
