import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyProfileService } from 'shared/service/profile/company-profile';
import { IRoutePath } from 'shared/interfaces/iroute-path';
import { RouterService } from 'shared/service/router/router-service';
import { IPublicClientApplication } from '@azure/msal-browser';
import { UserProfileService } from 'shared/service/profile/user-profile';
import { defaultCompany, defaultUser, IVipCompany, IVipUser } from 'redux/profileSlice';
import { defaultRole, IUserRole, RoleProfileService } from 'shared/service/profile/role-profile';
import { hasFilteredUsersByUserId } from 'App/User/user-role';
import { vlogger } from 'shared/service/logger/vlogger';
import { gridFilterActiveItemsLookupSelector } from '@mui/x-data-grid';

/**
 * Interface for App
 * @interface
 */
export interface IApp {
  companies: IVipCompany[];
  routes: IRoutePath[];
  flatRoutes: IRoutePath[];
  users: IVipUser[];
  roles: IUserRole[];
}

//  InitialState
const initialState: IApp = {
  companies: [defaultCompany],
  routes: [{ path: '/', displayName: 'Root', isDisplayed: false }],
  flatRoutes: [{ path: '/', displayName: 'Root', isDisplayed: false }],
  users: [defaultUser],
  roles: [defaultRole]
};

/**
 * Get routing array via Async Thunk
 */
export const fetchRoutes = createAsyncThunk(
  'app/fetchRoutes',
  async () => {
    const [routes, flatRoutes] = await new RouterService(vlogger).getRoutes();
    return { routes, flatRoutes };
  });

/**
 * Get company array via Async Thunk
 */
export const fetchCompanies = createAsyncThunk(
  'app/fetchCompanies',
  async (args: { pca: IPublicClientApplication; clientId?: number | undefined; userId?: number | undefined }, thunkApi) => {
    const companyProfileService = new CompanyProfileService(args.pca, vlogger);

    if (!args.clientId && !args.userId)
      return await companyProfileService.getCompanies();
    else if (args.clientId)
      return await companyProfileService.getCompanyByClientId(args.clientId);
    else if (args.userId)
      return await companyProfileService.getCompaniesByUserId(args.userId);
    else
      return null;
  }
);

/**
 * Get all registered users
 */
export const fetchUsers = createAsyncThunk(
  'app/fecthUsers',
  async (args: { pca: IPublicClientApplication; userId?: number; userFilter?: boolean }, thunkApi) => {
    const userProfileService = new UserProfileService(args.pca, vlogger);

    if (!args.userFilter)
      return await userProfileService.getUsers(args.userId);
    else if (args.userId)
      return await userProfileService.getUsersByUserId(args.userId);
    else
      return null;
  });

/**
 * Add or Update user to the all users list
 */
export const addUpdateUser = createAsyncThunk(
  'app/addUpdateUser',
  async (args: { pca: IPublicClientApplication; formUser: IVipUser; user: IVipUser }, thunkApi) => {
    const userProfileService = new UserProfileService(args.pca, vlogger);
    // await userProfileService.addUpdateUser(args.formUser, true);
    await userProfileService.addUpdateUser(args.formUser, false);

    const filtered = hasFilteredUsersByUserId(args.user.userRole);
    return (filtered)
      ? await userProfileService.getUsersByUserId(args.user.userId)
      : await userProfileService.getUsers(args.user.userId);
  }
);


/**
 * Get all roles
 */
export const fetchRoles = createAsyncThunk(
  'app/fecthRoles',
  async (pca: IPublicClientApplication) => {
    const roleProfileService = new RoleProfileService();
    return await roleProfileService.getRoles(pca);
  });

/**
 * Add or Update role to the all roles list
 */
export const addUpdateRole = createAsyncThunk(
  'app/addUpdateRole',
  async (args: { pca: IPublicClientApplication; role: IUserRole }, thunkApi) => {
    const roleProfileService = new RoleProfileService();
    await roleProfileService.addUpdateRole(args.pca, args.role, true);
    return roleProfileService.getRoles(args.pca);
  }
);


/**
 * App Slice
 */
export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    addUpdateUsers: (state, action) => {
      const newUser = action.payload as IVipUser;
      const users: IVipUser[] = state.users;

      const index = users
        .map((u) => {
          return u.email;
        })
        .indexOf(newUser.email);
      if (index > 0) {
        users[index] = newUser;
      } else {
        users.push(newUser);
      }

      state.users = users;
    },

    setRoutes: (state, action) => {

      state.routes = action.payload.routes as IRoutePath[];

      state.flatRoutes = action.payload.flatRoutes as IRoutePath[];
    },
  },
  extraReducers: (builder) => {
    //  fetchCompanies
    builder.addCase(fetchCompanies.pending, (state, action) => {
      // vlogger.debug('fetchCompanies pending');
    });

    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        state.companies = result;
      } else {
        vlogger.warning('fetchCompanies returned null');
      }
    });

    builder.addCase(fetchCompanies.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchCompanies failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.companies = initialState.companies;
    });


    //  fetchUsers
    builder.addCase(fetchUsers.pending, (state, action) => {
      // vlogger.debug('fecthUsers pending');
    });

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        const users = result.map((r) => {
          if (!r.displayName) {
            r.displayName = `${r.firstName} ${r.lastName}`;
          }
          return { ...r };
        });
        state.users = users;
      } else {
        vlogger.warning('fetchUsers returned null');
      }
    });

    builder.addCase(fetchUsers.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchUsers failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.users = initialState.users;
    });

    //  addUpdateUser
    builder.addCase(addUpdateUser.pending, (state, action) => {
      // vlogger.debug('addUpdateUser pending');
    });

    builder.addCase(addUpdateUser.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        const users = result.map((r) => {
          if (!r.displayName) {
            r.displayName = `${r.firstName} ${r.lastName}`;
          }
          return { ...r };
        });
        state.users = users;
      } else {
        vlogger.warning('addUpdateUser returned null');
      }
    });

    builder.addCase(addUpdateUser.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`addUpdateUser failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.users = initialState.users;
    });

    //  fetchRoles
    builder.addCase(fetchRoles.pending, (state, action) => {
      // vlogger.debug('fecthUsers pending');
    });

    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        state.roles = result;
      } else {
        vlogger.warning('fetchRoles returned null');
      }
    });

    builder.addCase(fetchRoles.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`fetchRole failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.roles = initialState.roles;
    });

    //  addUpdateUser
    builder.addCase(addUpdateRole.pending, (state, action) => {
      // vlogger.debug('addUpdateRole pending');
    });

    builder.addCase(addUpdateRole.fulfilled, (state, action) => {
      const result = action.payload;
      if (result) {
        state.roles = result;
      } else {
        vlogger.warning('addUpdateRole returned null');
      }
    });

    builder.addCase(addUpdateRole.rejected, (state, action) => {
      const error = action.error;
      vlogger.warning(`addUpdateRole failed\ncode: ${error.code}, name: ${error.name}, message: ${error.message}`);
      state.roles = initialState.roles;
    });
  },
});

export const { addUpdateUsers, setRoutes } = appSlice.actions;

export const appReducer = appSlice.reducer;

// export const selectCompanyByClientId: IVipCompany|undefined = ((state:RootState, clientId:number) =>
//     state.app.companies.find(e => e.company.clientId === clientId)?.company);
