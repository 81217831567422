import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePathname } from 'shared/hooks/usePathname';
import { models, Report as ReportModel, IEmbedSettings, Embed, IReportEmbedConfiguration, service } from 'powerbi-client';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { ReportService } from './report-service';
import { CardMedia } from '@mui/material';
import styled from '@emotion/styled';
import { vlogger } from 'shared/service/logger/vlogger';
import { StringHelper } from 'shared/utils/string-helper';
import appConstants from 'App/app-constants';


/**
 * Styling
 */
const PowerBiContainer = styled(CardMedia)`
 width: 100%;
 display: flex;
 overflow:hidden;
 margin: 0;
 padding: 0;

  & .report-style-class {
    height: 100vh;
    margin: 1% auto;
    width: 100%;
    }
 
  & iframe {
    border: 0px;
    min-width: 100%;
    top: 0px;
    left: 0px;
    }
 `;

export interface IContentModel {
  id: string;
  key: string;
  name: string;
  type: string;
}

export interface IClientMappingModel {
  institutionId: number;
  institutionName: string;
  workspaceId: string;
  contents: IContentModel[];
}

interface IReportNameModel {
  routeSegment: string;
  reportName: string;
  displayName: string;
}


export const Report = () => {
  const { instance } = useMsal();
  const { actor } = useAppSelector((state) => state.profile);
  const navigate = useNavigate();
  const [report, setReport] = useState<ReportModel>();
  const [reportId, setReportId] = useState<string>('');
  const [workspaceId, setWorkspaceId] = useState<string>('');
  const [reportNameModel, setReportNameModel] = useState<IReportNameModel[] | undefined>();
  const location = useLocation();

  // hard enforce conditions to access this page
  // useEffect(() => {
  //   if (actor.clientId !== appConstants.demo_clientId)
  //     navigate('/notauthorized');
  // }, [actor]);

  //  get route segment
  const pathname = usePathname();
  const routeSegment = pathname[pathname.length - 1].toLowerCase();
  const routeSection = pathname[pathname.length - 2].toLowerCase();


  const reportService = new ReportService();

  useEffect(() => {
    const fetch_id = async () => {
      let reportId = '';
      let workspaceId = '';

      const clientMapping = await reportService.getClientMapping(instance);

      if (clientMapping && clientMapping.length > 0) {
        reportId = reportService.getReportId(clientMapping, actor.clientId, routeSection);
        workspaceId = reportService.getWorkspaceId(clientMapping, actor.clientId);
      }

      vlogger.debug(`reportId: ${reportId}, workspaceId: ${workspaceId}`);
      setReportId(reportId);
      setWorkspaceId(workspaceId);
    };

    const fetch_pages = async () => {
      const pages = await reportService.getPagesForClient(actor.clientId, instance, routeSection);

      if (pages) {
        const models: IReportNameModel[] =
          pages?.map(p => {
            const model: IReportNameModel = {
              routeSegment: StringHelper.SpaceRemover(p.displayName).toLowerCase(),
              reportName: p.name,
              displayName: p.displayName,
            };
            return model;
          });
        setReportNameModel(models);
      }
    };

    void fetch_id();
    void fetch_pages();
  }, [actor, routeSection]);

  useEffect(() => {
    const changePage = (async () => {
      try {
        const pageName = reportNameModel?.find(n => n.routeSegment === routeSegment)?.reportName;
        const response = pageName && (await report?.setPage(pageName));
        if (response) {
          vlogger.debug(`statuscode: ${response.statusCode}, statusText: ${response.statusText}, ` +
            `headers: ${JSON.stringify(response.headers)}`);
        } else {
          vlogger.debug(`no response to report.setPage(${pageName})`);
        }
      } catch (e) {
        if (e instanceof Error) {
          vlogger.debug(`${e.name} error:  ${e.message}\ncause: ${e.cause}`);
          vlogger.debug(`stack: ${e.stack}`);
        }
      }
    });

    changePage();
  }, [report, routeSegment]);

  const reportSettings: IEmbedSettings = {
    bars: {
      actionBar: {
        visible: true
      }
    },
    panes: {
      bookmarks: {
        visible: false
      },
      fields: {
        expanded: true
      },
      filters: {
        expanded: false,
        visible: false
      },
      pageNavigation: {
        visible: false
      },
      selection: {
        visible: true
      },
      syncSlicers: {
        visible: true
      },
      // visualizations: {
      //   expanded: false
      // }
    },
    background: models.BackgroundType.Transparent,
    hyperlinkClickBehavior: models.HyperlinkClickBehavior.RaiseEvent,
    persistentFiltersEnabled: true,
    visualRenderedEvents: false,
  };


  const [embedConfig, setEmbedConfig] = useState<IReportEmbedConfiguration>({
    type: 'report',
    id: reportId,
    pageName: reportNameModel?.find(n => n.routeSegment === routeSegment)?.reportName,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.Read,
    viewMode: models.ViewMode.View,
  });
  const [embedToken, setEmbedToken] = useState<string>('');
  const [embedUrl, setEmbedUrl] = useState<string>('');

  useEffect(() => {
    const fetchUrl = async () => {
      const accessToken: string = await reportService.getPowerBiAccessToken(instance);
      const token = await reportService.getPowerBiEmbedToken(workspaceId, reportId, accessToken);
      const url = await reportService.getEmbedUrl(workspaceId, reportId, accessToken);

      vlogger.debug(`embedToken: ${token},\n embedUrl: ${url}`);
      setEmbedToken(token);
      setEmbedUrl(url);
    };

    void fetchUrl();
  }, [reportId, workspaceId]);

  useEffect(() => {
    const config: IReportEmbedConfiguration = {
      type: 'report',   // Supported types: report, dashboard, tile, visual and qna
      id: reportId,
      pageName: reportNameModel?.find(n => n.routeSegment === routeSegment)?.reportName,
      tokenType: models.TokenType.Embed,
      embedUrl: embedUrl,
      accessToken: embedToken,
      settings: reportSettings,
      viewMode: models.ViewMode.View,
      permissions: models.Permissions.All,
    };

    vlogger.debug(`embedConfig: ${JSON.stringify(config)}`);
    setEmbedConfig(config);
  }, [embedToken, embedUrl, routeSegment, reportNameModel, reportId]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap: Map<string, EventHandler> = new Map([
    ['loaded', function () {
      vlogger.debug('Report has loaded');
    }],
    ['rendered', function () {
      vlogger.debug('Report has rendered');
    }],
    ['saved', function (event) {
      vlogger.debug('Report saved - *** THIS SHOULD NEVER HAPPEN ***');
      if (event) {
        vlogger.debug(`event: ${event.timeStamp}\ttype: ${event.type}`);
        vlogger.debug(`details: ${event.detail}`);
      }
    }],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ['error', async function (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) {
      if (event) {
        vlogger.error(event.detail);
        vlogger.error(`${await embeddedEntity?.getCorrelationId()}`);
      }
    }],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ['pageChanged', function (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) {
      vlogger.debug('Report has changed pages');

      if (event) {
        vlogger.debug(`event: ${event.timeStamp}\ttype: ${event.type}`);
        vlogger.debug(`details: ${event.detail}`);
        vlogger.debug(`detail.page: ${event.detail.newPage as models.IPage}`);
        vlogger.debug(`embeddEntity: ${embeddedEntity?.getId()}`);
      }
    }]
  ]);

  return (
    <PowerBiContainer id='powerbi-iframe'>
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={'report-style-class'}

        getEmbeddedComponent={(embedObject: Embed) => {
          vlogger.informational(`Embedded object of type "${embedObject.embedtype}" received`);
          setReport(embedObject as ReportModel);
        }}
      />
    </PowerBiContainer>
  );
};

Report.displayName = 'Insights';
