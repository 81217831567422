import { IPublicClientApplication } from '@azure/msal-browser';
import { HttpService, IHeaders } from 'shared/service/http/http-service';
import { getAccessToken } from 'shared/service/http/token-retriever';
import { vlogger } from 'shared/service/logger/vlogger';
import { GuidHelper } from 'shared/utils/guid-helper';
import { JwtHelper } from 'shared/utils/jwt-helper';
import { VelocityErrorHelper } from 'shared/utils/velocity-error-helper';


/**
 * Branch Goals
 */
export interface IBranchGoals {
  clientId: number;
  branchName: string;
  branchNumber: number;
  goal: number | null;
  effectiveDate: string | null;
  created: string | null;
  lastModified: string | null;
}

/**
 * Branch Goals request / response interface
 */
export interface IBranchGoalsRequest {
  clientId?: number;
  orderBy?: boolean;   //  false returns ascending order; true returns descending order
}

export interface IBranchGoalsAdd {
  clientId: number;
  branchNumber: number;
  updatedBy: number;
  goal: number;
  effectiveDate: string;
}

export interface IBranchGoalsAddRequest {
  goals: IBranchGoalsAdd[];
}


/**
 * Client Settings Service Interface  - use for DI (or DI like)
 * @interface
 */
export interface IBranchGoalsService {
  getBranchGoals(pca: IPublicClientApplication, request: IBranchGoalsRequest): Promise<IBranchGoals[] | null>;
  addBranchGoals(pca: IPublicClientApplication, request: IBranchGoalsAdd[]): Promise<boolean>;
}


/** 
 * Class representing the Branch Goals Service http interface object
 * @class
 * @implements IBranchGoalsService
 */
export class BranchGoalsService implements IBranchGoalsService {

  /**
   * Get branch goals for a specific client
   * @param {IPublicClientApplication} pca authentication service
   * @param {IBranchGoalsRequest} request client id to get their branch goals
   * @returns {IBranchGoals[] | null} list of branch goals
   */
  async getBranchGoals(pca: IPublicClientApplication, request: IBranchGoalsRequest): Promise<IBranchGoals[] | null> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Branch Goals for a specific client id
    const result =
      await httpService.get<IBranchGoals[]>('api/v1/client/branchGoal/getByClientId', request, headers, uuid);

    if (result === null) return null;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
    }

    return result.result;
  }


  /**
 * Add a branch goal
 * @param {IPublicClientApplication} pca authentication service
 * @param {IBranchGoalsRequest[]} request array of branch goals request objects
 * @returns {boolean} success
 */
  async addBranchGoals(pca: IPublicClientApplication, request: IBranchGoalsAdd[]): Promise<boolean> {
    const uuid = GuidHelper.NewGuid();
    const baseUrl = import.meta.env.VITE_APP_VELOCITY_API_BASE_URL || '';
    const accessToken = await getAccessToken(pca);
    const headers: IHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };
    const r: IBranchGoalsAddRequest = { goals: request };
    const httpService = new HttpService(vlogger, baseUrl, headers);

    //  HTTP Get for Client FeatureFlag
    const result =
      await httpService.post<IBranchGoalsAddRequest, boolean | null>('api/v1/client/branchGoal/addBranchGoals', r, headers, uuid);

    if (result === null) return false;
    if (result.errors.length !== 0) {
      VelocityErrorHelper.Logify(result.errors, result.operationId, false, false);
      return false;
    }

    return true;
  }
}
