import { BrowserCacheLocation, Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies as policies } from './policies';

/* istanbul ignore file */

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: (import.meta.env.VITE_APP_IDENTITY_CLIENTID || '').trim(),
    authority: policies.authorities.signUpSignIn.authority,
    knownAuthorities: [policies.authorityDomain],

    // By default, MSAL is configured to set the redirect URI to the current page that it is running on.
    // redirectUri: window.location.href,
    // by setting navigateToLoginRequestUrl = false, the user is always redirected to a specific url after login.
    redirectUri: (import.meta.env.VITE_APP_IDENTITY_REDIRECT_URI || '').trim(),
    navigateToLoginRequestUrl: false,

    //  The redirect URI where a browser navigates after a successful logout.
    postLogoutRedirectUri: (import.meta.env.VITE_APP_IDENTITY_REDIRECT_URI || '').trim(),
  },
  cache: {
    // cacheLocation: BrowserCacheLocation.LocalStorage,
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true, // Adds the Secure flag to the browser cookie so it can only be sent over HTTPS
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 6000,
    asyncPopups: false,
    allowRedirectInIframe: false, // Set this to "true" to allow redirect operations to be initiated when the application is inside an iframe
    allowNativeBroker: false, //  Disable Web Account Manager (WAM) on Windows

    loggerOptions: {
      piiLoggingEnabled: false, //  Note:  this should be disable for production
      logLevel: LogLevel.Warning,
      // logLevel: LogLevel.Verbose,
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
  telemetry: {
    application: {
      appName: 'VIP-msal',
      appVersion: '1.0.0',
    },
  },
};

/**
 * Scopes for id token to be used at MS Identity Platform endpoints.
 */
export const LOGINREQUEST: RedirectRequest = {
  scopes: [
    'openid',
    'profile',
    'email',
    'offline_access',
    `https://${policies.identityDomain}/api/User.ReadWrite`,
    `https://${policies.identityDomain}/api/Client.ReadWrite`,
  ],
};

/**
 * Endpoints for MS Graph API services
 */
export const GRAPHCONFIG = {
  baseUrl: 'https://graph.microsoft.com/v1.0/',
  meUrl: '/me',
  // graphMeEndpoint: UrlHelper.AppendUrl(baseUrl, meUrl);
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const GRAPHLOGINREQUEST = {
  scopes: [`https://${policies.authorityDomain}/User.Read`],
};
