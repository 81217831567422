import { ILoggerHttpTransport, LoggerHttpTransport } from './vlogger_http';

/**
 * Velocity logger interface
 * @interface
 */
export interface ILogger {
  critical(message: string, trace?: boolean, http?: boolean): void;
  error(message: string, trace?: boolean, http?: boolean): void;
  warning(message: string, trace?: boolean, http?: boolean): void;
  informational(message: string, trace?: boolean, http?: boolean): void;
  debug(message: string, trace?: boolean, http?: boolean): void;
  verbose(message: string, trace?: boolean, http?: boolean): void;
}

/**
 * Log Level enum
 */
export enum LogLevel { VERBOSE, DEBUG, INFORMATIONAL, WARNING, ERROR, CRITICAL }

/**
 * Velocity Logger
 * @class
 */
export class Logger implements ILogger {
  private _httpTransport: ILoggerHttpTransport;
  private _minLogLevel: LogLevel = LogLevel.WARNING;

  /**
   * CTOR
   * @constructor
   */
  constructor() {
    this._httpTransport = new LoggerHttpTransport(this);

    if (!!import.meta.env.VITE_APP_LOGLEVEL && import.meta.env.MODE !== 'test') {
      const minAppLogLevel = (import.meta.env.VITE_APP_LOGLEVEL)?.trim().toUpperCase();
      this._minLogLevel = LogLevel[minAppLogLevel as keyof typeof LogLevel];
    } else if (!import.meta.env.PROD) {
      this._minLogLevel = LogLevel.VERBOSE;
    }

    //  catch all for 'undefined' or null _minLogLevel
    // eslint-disable-next-line eqeqeq
    if (this._minLogLevel == null) {
      this._minLogLevel = LogLevel.WARNING;
    }
  }

  /**
   * console log write function
   * @param {LogLevel} logLevel 
   * @param {LogLevel} minLogLevel 
   * @param {string }message 
   * @param {boolean} trace use console.trace to ouput stack trace
   * @returns void
   */
  private log_write(logLevel: LogLevel, minLogLevel: LogLevel, message: string, trace: boolean) {
    if (logLevel < minLogLevel) return;

    if (trace) {
      console.trace(message);
    } else {
      console.log(message);
    }
  }

  /**
   * log  function
   * @param {LogLevel} logLevel 
   * @param {LogLevel} minLogLevel 
   * @param {string } message 
   * @param {boolean?} trace use console.trace to ouput stack trace
   * @param {boolean?} http send log to error api
   * @returns void
   */
  private log(logLevel: LogLevel, preamble: string, message: string, trace?: boolean, http?: boolean) {
    const writeMessage = `${preamble}: ${message}`;
    this.log_write(logLevel, this._minLogLevel, writeMessage, !!trace);

    const isHttp = (http === true) || (!!http && logLevel > LogLevel.INFORMATIONAL);
    if (isHttp) {
      this._httpTransport.log(logLevel, writeMessage);
    }
  }

  critical(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[31mcritical\x1b[0m';
    this.log(LogLevel.CRITICAL, preamble, message, trace, http);
  }

  error(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[31merror\x1b[0m';
    this.log(LogLevel.ERROR, preamble, message, trace, http);
  }

  warning(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[33mwarning\x1b[0m';
    this.log(LogLevel.WARNING, preamble, message, trace, http);
  }

  informational(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[0minformational\x1b[0m';
    this.log(LogLevel.INFORMATIONAL, preamble, message, trace, http);
  }

  debug(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[0mdebug\x1b[0m';
    this.log(LogLevel.DEBUG, preamble, message, trace, http);
  }

  verbose(message: string, trace?: boolean, http?: boolean): void {
    const preamble = '\x1b[34mverbose\x1b[0m';
    this.log(LogLevel.VERBOSE, preamble, message, trace, http);
  }
}


export const vlogger: ILogger = new Logger();
