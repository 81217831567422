//  TODO:  Keep?
/* istanbul ignore file */

/**
 * Error class
 */
export class VelocityError {
  message = '';

  constructor(message: string) {
    this.message = message;
  }
}
